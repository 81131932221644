import React from 'react';

import { Col, Row } from 'reactstrap';
import { AnimateIn, Flex, FlexRow } from '../components';
import GlobeIcon from '../components/Icons/GlobeIcon';
import SideDiamond from '../components/SideDiamond/SideDiamond';
import Markdown from '../components/Markdown/Markdown';

import safetyConsultingDataJson from '../data/safetyConsulting.json';
import SidePageLayout from '../components/SidePageLayout/SidePageLayout';

const DiamondGlobeIcon = <GlobeIcon width={50} />

export const SafetyConsultingPageInternal = (safetyConsultingData: typeof safetyConsultingDataJson) => {
  return (
    <SidePageLayout
      title="Safety Consulting"
      type="technology"
      Header={(
        <FlexRow className="ml-xl-5 px-3" fill alignCenter overflowHidden>
          <div>
            <img className="img-fluid" alt="Aviation Safety Solutions" src={safetyConsultingData.headerImg} />
          </div>
        </FlexRow>
      )}
      callToActionTagline={safetyConsultingData.callToActionTagline}
    >
      <Row>
        <Col className="d-none d-md-block" md={4}>
          <Flex center className="side-affix">
            <SideDiamond label={<span>Safety<br/>Consulting</span>} icon={DiamondGlobeIcon} />
          </Flex>
        </Col>
        <Col md={7} className="side-page-top-spacer">
          <AnimateIn tag="section" className="px-2 pt-5 body-bg">
            <h1 className="page-heading">TRANSPORTATION & TECHNOLOGY</h1>
            <Markdown source={safetyConsultingData.mainMarkdown} />
          </AnimateIn>
        </Col>
      </Row>
    </SidePageLayout>
  );
};

export default () => <SafetyConsultingPageInternal {...safetyConsultingDataJson} />;
